var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',_vm._g(_vm._b({},'v-app-bar',_vm.$attrs,false),_vm.$listeners),[_c('v-btn',{class:{
        'mr-3': !_vm.$vuetify.rtl,
        'ml-3': _vm.$vuetify.rtl,
    },attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('drawer')}}},[_c('v-icon',[_vm._v("mdi-menu")])],1),_vm._v(" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-spacer'):_vm._e(),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{class:{
        'd-flex align-center no-select ml-3': true,
        'ml-3 pr-md-6': !_vm.$vuetify.rtl,
        'mr-3 pl-md-6': _vm.$vuetify.rtl,
    },staticStyle:{"gap":"1rem"}},[_c('v-img',{attrs:{"src":_vm.logo,"width":"2rem"}}),_vm._v(" "),_c('div',{staticClass:"title mb-n1"},[_vm._v("Polymind")])],1):_vm._e(),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp)?[_c('portal-target',{attrs:{"name":"desktop_nav","slim":""}})]:_vm._e(),_vm._v(" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('portal-target',{attrs:{"name":"title","slim":""}},[_c('v-app-bar-title',[_c('span',[_vm._v("Polymind")])])],1):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var menuAttrs = ref.attrs;
    var menuOn = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var tooltipAttrs = ref.attrs;
    var tooltipOn = ref.on;
return [_c('v-btn',_vm._g(_vm._b({class:{
                        'ml-3': !_vm.$vuetify.rtl,
                        'mr-3': _vm.$vuetify.rtl,
                    },attrs:{"icon":""}},'v-btn',Object.assign({}, menuAttrs, tooltipAttrs),false),Object.assign({}, menuOn, tooltipOn)),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.options'))}})])]}}])},[_vm._v(" "),_c('v-list',{staticClass:"text-no-wrap"},[_c('portal-target',{attrs:{"name":"options"}}),_vm._v(" "),_c('v-list-item',{on:{"click":_vm.handleSwitchThemeClick}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1),_vm._v(" "),_c('v-list-item-content',{domProps:{"textContent":_vm._s(_vm.$t('toolbar.themeSwitchTooltip'))}})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }