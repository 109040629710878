var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-sheet',{attrs:{"color":_vm.$vuetify.breakpoint.smAndDown ? 'primary' : null,"dark":_vm.$vuetify.breakpoint.smAndDown}},[_c('v-list-item',{staticClass:"pt-4 d-flex align-center"},[_c('div',{staticClass:"d-flex align-center justify-center no-select",staticStyle:{"gap":"1rem","flex":"1"}},[_c('img',{attrs:{"src":_vm.logo,"height":"37"}}),_vm._v(" "),_c('div',{staticClass:"title mb-n1"},[_vm._v("Polymind")])]),_vm._v(" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();_vm._value = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),(_vm.$root.user.id)?_c('v-list-item',{staticClass:"mt-2"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"text-truncate caption",staticStyle:{"width":"180px"},domProps:{"textContent":_vm._s(_vm.$root.user.email)}}),_vm._v(" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,1089517280)},[_vm._v(" "),_c('v-list',[_vm._l((_vm.accounts),function(account){return _c('v-list-item',{key:account.email,on:{"click":function () { return _vm.handleSwitchAccountClick(account); }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"secondaryDark","left":""}},[_vm._v("mdi-account")])],1),_vm._v(" "),_c('v-list-item-content',[_c('span',{domProps:{"textContent":_vm._s(account.email)}})])],1)}),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('logout')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"secondaryDark","left":""}},[_vm._v("mdi-logout-variant")])],1),_vm._v(" "),_c('v-list-item-content',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('accountMenu.logout'))}})])],1)],2)],1)],1):_vm._e(),_vm._v(" "),_c('v-divider',{staticClass:"mt-4"})],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-sheet',{attrs:{"color":"background"}},[_c('v-divider'),_vm._v(" "),_c('div',{staticClass:"pa-4"},[(_vm.socialLinks.length > 0)?_c('div',{staticClass:"d-flex align-center justify-center"},_vm._l((_vm.socialLinks),function(link,linkIdx){return _c('v-tooltip',{key:linkIdx,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":link.href,"target":"_blank","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondaryDark"},domProps:{"textContent":_vm._s(link.icon)}})],1)]}}],null,true)},[_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(link.tooltip)}})])}),1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mt-2"},[_c('LanguageSwitcher',{attrs:{"btn-class":"px-0","color":"secondaryDark","outlined":"","dense":"","hide-details":""},on:{"input":_vm.handleLanguageSwitch},model:{value:(_vm.$i18n.locale),callback:function ($$v) {_vm.$set(_vm.$i18n, "locale", $$v)},expression:"$i18n.locale"}})],1),_vm._v(" "),_c('div',{staticClass:"caption opacity-75 text-center mt-2"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('footer.copyright', {
                        name: 'Polymind',
                        version: _vm.version,
                        year: _vm.year,
                    }))}}),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('footer.allRights'))}})])])],1)]},proxy:true}]),model:{value:(_vm._value),callback:function ($$v) {_vm._value=$$v},expression:"_value"}},'v-navigation-drawer',_vm.$attrs,false),_vm.$listeners),[_vm._v(" "),_c('v-list',{attrs:{"dense":""}},[_c('div',{staticClass:"px-2 pb-4"},[_c('v-text-field',{attrs:{"id":"sidebar_search","placeholder":_vm.$t('sidebar.decks.title'),"prepend-inner-icon":"mdi-magnify","solo":_vm.$vuetify.theme.dark,"outlined":!_vm.$vuetify.theme.dark,"dense":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('DeckTree',{model:{value:(_vm._decks),callback:function ($$v) {_vm._decks=$$v},expression:"_decks"}}),_vm._v(" "),(_vm._decks.length === 0 && _vm._search.length > 0)?_c('div',{staticClass:"px-2"},[_c('v-alert',{attrs:{"type":"info","text":"","outlined":""}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('sidebar.decks.noResults'))}})])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }