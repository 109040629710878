var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._g(_vm._b({attrs:{"items":_vm.items,"dense":_vm.dense},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
				'd-flex align-center w-100 text-no-wrap': true,
				'caption': _vm.dense,
			}},[_c('v-icon',{attrs:{"x-small":_vm.dense,"left":!_vm.$vuetify.rtl,"right":_vm.$vuetify.rtl}},[_vm._v("mdi-translate")]),_vm._v(" "),(!_vm.iconOnly)?[(_vm.abbreviation)?_c('div',{domProps:{"textContent":_vm._s(item.text.toUpperCase())}}):_c('div',{domProps:{"textContent":_vm._s(item.text)}})]:_vm._e()],2)]}},{key:"item",fn:function(ref){
			var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.text)}})]}}]),model:{value:(_vm._value),callback:function ($$v) {_vm._value=$$v},expression:"_value"}},'v-select',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }