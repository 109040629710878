var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',_vm._g(_vm._b({attrs:{"persistent":_vm.persistent || _vm.disabled,"dark":_vm.dark,"fullscreen":_vm.fullscreen},model:{value:(_vm._value),callback:function ($$v) {_vm._value=$$v},expression:"_value"}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"color":_vm.color,"disabled":_vm.disabled,"tile":_vm.$vuetify.breakpoint.smAndDown}},[_c('v-expand-transition',[(!_vm.hideMobileFocus)?_c('v-card-title',{class:{
                    'background': !_vm.dense && !_vm.dark && !_vm.$vuetify.breakpoint.smAndDown,
                    'primary white--text': !_vm.dense && !_vm.dark && _vm.$vuetify.breakpoint.smAndDown,
                    'py-0': _vm.$vuetify.breakpoint.smAndDown,
                }},[_c('div',{class:{
                        'w-100 d-flex align-center flex-nowrap justify-space-between': true,
                        'py-3': _vm.$vuetify.breakpoint.smAndDown,
                    }},[_vm._t("title",function(){return [_c('div',{staticClass:"d-flex align-center overflow-hidden"},[(_vm.icon)?_c('v-icon',{attrs:{"left":!_vm.$vuetify.rtl,"right":_vm.$vuetify.rtl},domProps:{"textContent":_vm._s(_vm.icon)}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"headline text-truncate",domProps:{"textContent":_vm._s(_vm.title)}})],1)]}),_vm._v(" "),_c('v-btn',{attrs:{"disabled":_vm.disabled,"dark":!_vm.dense && _vm.$vuetify.breakpoint.smAndDown,"icon":""},on:{"click":function($event){_vm._value = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],2)]):_vm._e()],1),_vm._v(" "),_c('v-card-text',{class:{ 'py-8': !_vm.dense },staticStyle:{"flex":"1"}},[_vm._t("body",function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.body)}})]})],2),_vm._v(" "),_c('v-expand-transition',[(!_vm.hideMobileFocus)?_c('div',[_c('v-divider'),_vm._v(" "),_c('v-card-actions',{class:{
                        'py-4 py-md-2': true,
                        'background': !_vm.dense && !_vm.dark,
                    }},[_vm._t("actions",function(){return [_c('div',{staticClass:"d-flex flex-column flex-md-row w-100 justify-end",staticStyle:{"gap":"0.5rem"}},[_vm._t("buttons",function(){return _vm._l((_vm.buttons),function(btn,btnIdx){return _c('v-btn',_vm._g(_vm._b({key:btnIdx,attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"outlined":btn.type === 'cancel' || (btn.attrs && btn.attrs.outlined),"large":""},on:{"click":function($event){btn.type === 'cancel' ? _vm._value = false : null}}},'v-btn',btn.attrs,false),btn.events),[(btn.icon)?_c('v-icon',{attrs:{"left":!_vm.$vuetify.rtl,"right":_vm.$vuetify.rtl},domProps:{"textContent":_vm._s(btn.icon)}}):_vm._e(),_vm._v(" "),(btn.type === 'cancel')?_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.cancel'))}}):_c('span',{domProps:{"textContent":_vm._s(btn.text)}})],1)})})],2)]})],2)],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }