import { render, staticRenderFns } from "./Play.vue?vue&type=template&id=1e88c5bd&scoped=true&"
import script from "./Play.vue?vue&type=script&lang=js&"
export * from "./Play.vue?vue&type=script&lang=js&"
import style0 from "./Play.vue?vue&type=style&index=0&id=1e88c5bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e88c5bd",
  null
  
)

export default component.exports