var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Snack',_vm._b({attrs:{"transition":"slide-y-reverse-transition","bottom":"","left":""},model:{value:(_vm.$root.snack.visible),callback:function ($$v) {_vm.$set(_vm.$root.snack, "visible", $$v)},expression:"$root.snack.visible"}},'Snack',_vm.$root.snack,false)),_vm._v(" "),_c('ErrorHandler',{attrs:{"value":_vm.$root.error,"max-width":"500","icon":"mdi-alert","color":"error","dark":"","scrollable":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown}}),_vm._v(" "),_c('GlobalModal',_vm._b({attrs:{"scrollable":""},model:{value:(_vm.$root.modal.visible),callback:function ($$v) {_vm.$set(_vm.$root.modal, "visible", $$v)},expression:"$root.modal.visible"}},'GlobalModal',Object.assign({}, _vm.$root.modal, _vm.$root.modal.attrs),false)),_vm._v(" "),_c('v-main',[_c('Modal',{attrs:{"title":_vm.$t('restricted.termsModal.title'),"body":_vm.$t('restricted.termsModal.body'),"buttons":[
					{ text: _vm.$t('btn.close'), attrs: { outlined: true }, events: { click: function () { this$1.termsModal = false } } } ],"icon":"mdi-gavel","max-width":"600","scrollable":""},model:{value:(_vm.termsModal),callback:function ($$v) {_vm.termsModal=$$v},expression:"termsModal"}}),_vm._v(" "),_c('v-sheet',{staticClass:"h-100 d-flex flex-column py-4 py-md-0",attrs:{"color":_vm.background}},[_c('v-sheet',{staticClass:"h-100 d-flex align-center justify-center",staticStyle:{"flex":"1"},attrs:{"color":"transparent"}},[_c('v-card',{staticClass:"text-center w-100",attrs:{"max-width":_vm.maxWidth,"tile":_vm.flat,"flat":_vm.flat}},[_c('v-card-text',{style:(_vm.logoStyle)},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-img',{attrs:{"src":_vm.logo,"max-width":_vm.logoSize,"height":_vm.logoSize,"contain":""}}),_vm._v(" "),_c('h1',{staticClass:"primary--text display-1 mt-2",staticStyle:{"flex":"1"},domProps:{"textContent":_vm._s(_vm.$t('restricted.title.' + _vm.$route.name))}},[_vm._v("Polymind")])],1)]),_vm._v(" "),_c('v-card-text',[_c('router-view',{on:{"terms":function($event){_vm.termsModal = true}}})],1)],1)],1),_vm._v(" "),_c('v-footer',{staticClass:"d-block d-md-flex text-center justify-md-space-between justify-center caption",attrs:{"color":_vm.$vuetify.theme.dark ? 'transparent' : _vm.background}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('LanguageSwitcher',{staticClass:"mb-1 ml-1",staticStyle:{"max-width":"8rem"},attrs:{"menu-props":"auto","abbreviation":"","dense":"","hide-details":""},on:{"input":_vm.handleLanguageSwitch},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}}),_vm._v(" "),(_vm.$root.socialLinks.length > 0)?_c('div',{staticClass:"d-flex align-center justify-center mb-4"},_vm._l((_vm.$root.socialLinks),function(link,linkIdx){return _c('v-tooltip',{key:linkIdx,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var attrs = ref.attrs;
				var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":link.href,"target":"_blank","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondaryDark"},domProps:{"textContent":_vm._s(link.icon)}})],1)]}}],null,true)},[_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(link.tooltip)}})])}),1):_vm._e()],1):_vm._e(),_vm._v(" "),_c('span',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('footer.copyright', {
                            name: 'Polymind',
                            version: _vm.version,
                        }))}}),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('footer.allRights'))}})]),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"d-inline d-md-flex align-center justify-center text-no-wrap",staticStyle:{"flex":"0","gap":"2rem"}},[(_vm.$root.socialLinks.length > 0)?_c('div',{staticClass:"d-flex align-center justify-center order-first"},_vm._l((_vm.$root.socialLinks),function(link,linkIdx){return _c('v-tooltip',{key:linkIdx,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var attrs = ref.attrs;
                        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":link.href,"target":"_blank","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondaryDark"},domProps:{"textContent":_vm._s(link.icon)}})],1)]}}],null,true)},[_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(link.tooltip)}})])}),1):_vm._e(),_vm._v(" "),_c('LanguageSwitcher',{staticClass:"mb-1 order-last",attrs:{"menu-props":"left","abbreviation":"","dense":"","hide-details":""},on:{"input":_vm.handleLanguageSwitch},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}})],1):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }