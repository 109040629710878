var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.breakpoint.mdAndUp)?_c('v-card',_vm._g(_vm._b({},'v-card',_vm.$attrs,false),_vm.$listeners),[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.cards,"footer-props":{
            itemsPerPageText: _vm.$t('table.itemsPerPageText'),
            itemsPerPageOptions: [10,25,50,100,-1],
        },"search":_vm.search,"loading":_vm.loading,"items-per-page":10,"sort-by":"created_at","sort-desc":"","show-select":""},scopedSlots:_vm._u([(_vm.cards.length > 10)?{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('td',{staticClass:"py-3",attrs:{"colspan":"10"}},[_c('v-text-field',{staticClass:"w-100",attrs:{"id":"card_listing_search_mobile","placeholder":_vm.$t('cardListing.searchPlaceholder'),"prepend-inner-icon":"mdi-magnify","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])])]},proxy:true}:null,{key:"footer.prepend",fn:function(){return [_c('BulkActionMenu',{attrs:{"deck":_vm.deck,"cards":_vm._cards,"selected":_vm._selected,"voices":_vm.voices,"btn-attrs":{ outlined: true },"top":"","offset-y":""},on:{"update:cards":function($event){_vm._cards=$event},"update:selected":function($event){_vm._selected=$event},"update":_vm.handleUpdate,"totalCard":function (amount) { return _vm.$emit('totalCard', amount); }}}),_vm._v(" "),_c('BulkActionMenu',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"bulkAction",attrs:{"deck":_vm.deck,"cards":_vm._cards,"selected":_vm.singleSelected,"voices":_vm.voices,"btn-attrs":{ outlined: true },"top":"","offset-y":""},on:{"update:cards":function($event){_vm._cards=$event},"update:selected":function($event){_vm.singleSelected=$event},"update":_vm.handleUpdate,"totalCard":function (amount) { return _vm.$emit('totalCard', amount); }}})]},proxy:true},{key:"loading",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('table.loading'))+"\n        ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('table.noData'))+"\n        ")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('table.noResults'))+"\n        ")]},proxy:true},{key:"item.front",fn:function(ref){
        var item = ref.item;
return [_c('ItemSide',{attrs:{"audio":item.front_synthesized,"voice":item.front_voice},model:{value:(item.front),callback:function ($$v) {_vm.$set(item, "front", $$v)},expression:"item.front"}})]}},{key:"item.back",fn:function(ref){
        var item = ref.item;
return [_c('ItemSide',{attrs:{"audio":item.back_synthesized,"voice":item.back_voice,"primary":""},model:{value:(item.back),callback:function ($$v) {_vm.$set(item, "back", $$v)},expression:"item.back"}})]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("moment")(item.created_at,'YYYY-MM-DD HH:mm:ss'))+"\n        ")]}},{key:"item._action",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleEditCardClick(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.edit'))}})]),_vm._v(" "),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteCardClick(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.delete'))}})])],1)]}}],null,true),model:{value:(_vm._selected),callback:function ($$v) {_vm._selected=$$v},expression:"_selected"}})],1):_c('v-sheet',_vm._g(_vm._b({class:{
    'fill-height': true,
    'd-flex align-center justify-center': !_vm.loading && _vm.cards.length === 0,
},attrs:{"color":"transparent"}},'v-sheet',_vm.$attrs,false),_vm.$listeners),[(_vm.cards.length > 10)?_c('div',{staticClass:"mb-3"},[_c('v-text-field',{staticClass:"w-100",attrs:{"placeholder":_vm.$t('cardListing.searchPlaceholder'),"prepend-inner-icon":"mdi-magnify","solo":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_vm._v(" "),(_vm.search.length > 0 && _vm._cards.length === 0)?_c('v-alert',{attrs:{"type":"info","text":"","outlined":"","prominent":""}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('cardListing.noResults'))}})]):(!_vm.loading && _vm.cards.length === 0)?_c('div',{staticClass:"text-center mx-auto",staticStyle:{"max-width":"15rem"}},[_c('v-icon',{attrs:{"color":"warning","x-large":""}},[_vm._v("mdi-alert")]),_vm._v(" "),_c('h3',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$t('cardListing.emptyWarning'))}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm._cards),function(card,cardIdx){return _c('v-card',{key:card.id,class:{
        'mt-3': cardIdx > 0,
    },attrs:{"color":_vm.isSelected(card) ? 'primary' : null,"dark":_vm.isSelected(card)},on:{"click":function($event){return _vm.handleCardClick(card)}}},[_c('v-card-text',{staticClass:"text-break"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":_vm.deck.data.single ? 12 : 6}},[(_vm.skeleton)?_c('v-skeleton-loader',{staticClass:"mb-n2",attrs:{"type":"text"}}):_c('ItemSide',{attrs:{"audio":card.front_synthesized,"voice":card.front_voice,"selected":_vm.isSelected(card)},model:{value:(card.front),callback:function ($$v) {_vm.$set(card, "front", $$v)},expression:"card.front"}})],1),_vm._v(" "),(!_vm.deck.data.single)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.skeleton)?_c('v-skeleton-loader',{staticClass:"mb-n2",attrs:{"type":"text"}}):_c('ItemSide',{attrs:{"audio":card.back_synthesized,"voice":card.back_voice,"primary":"","selected":_vm.isSelected(card)},model:{value:(card.back),callback:function ($$v) {_vm.$set(card, "back", $$v)},expression:"card.back"}})],1):_vm._e()],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }